import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Topbar from "../../components/topbar/Topbar";
import Sidebar from "../../components/sidebar/Sidebar";
import BusinessToggle from "../../components/businessToggle/BusinessToggle";
import ImageSlider from "../../components/imageSlider/ImageSlider";
import axios from "axios";
import "./trade.css";
import { Star, MapPin, Wifi, Car, Clock, Share, ExternalLink } from "lucide-react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export default function PremiumDetails() {
  const { id } = useParams();
  const [apiData, setApiData] = useState([]);
  const navigate = useNavigate();

  const getData = async () => {
    try {
      const response = await axios.get(
        `https://ahmedabadsubha.com/api/mobile/classified-jobs/${id}`
      );
      setApiData(response?.data?.data);
    } catch (e) {
      console.log("error from free listing page", e);
    }
  };

  const slides = [];
  for (let i = 1; i <= 10; i++) {
    const image = apiData?.[`image_${i}`];
    if (image !== null) {
      slides.push(image);
    }
  }

  const [showShare, setShowShare] = useState(false); 
  const shareButton = () => {
      setShowShare(!showShare); 
  }

  const currentUrl = window.location.href; 
  const modCurrentUrl = currentUrl 
  // + "post/" + post.id; 
  const [showCopied, setShowCopied] = useState(false); 
  const CopyUrl = () => {
      navigator.clipboard.writeText(modCurrentUrl); 
      setShowCopied(true); 
      setTimeout(() => {
          setShowCopied(false); 
      }, 1000);
  }

  useEffect(() => {
    getData();
  }, []);
  console.log(apiData, slides);

  return (
    <>
      <div>
        <Topbar />
        <div className="businessContainer">
          {/* <div className="premiumSidebar">
            <Sidebar />
          </div> */}
          <div className="tradeWrapper" style={{ position: "relative" }}>
            {/* <div>
              <>
                <div className="tradeRedirectImgContainer">
                  {apiData.image_2 ? (
                    <div className="tradeRedirectMainImg">
                      <ImageSlider slides={slides} />
                    </div>
                  ) : (
                    <img
                      src={apiData.image_1}
                      alt="business img"
                      className="tradeRedirectMainImg"
                    />
                  )}
                </div>

                <div className="premiumInfoContainer">
                  <div>
                    <p style={{ fontWeight: "bold" }}>Name:</p>
                    <p>{apiData?.userInfo?.name}</p>
                  </div>
                  <hr style={{ marginTop: 5, color: "gray" }} />
                  <div>
                    <p style={{ fontWeight: "bold" }}>Business Name:</p>
                    <p>{apiData?.business_name}</p>
                  </div>
                  <hr style={{ marginTop: 5, color: "gray" }} />
                  <div>
                    <p style={{ fontWeight: "bold" }}>Brand Name:</p>
                    <p>{apiData?.brand_name}</p>
                  </div>
                  <hr style={{ marginTop: 5, color: "gray" }} />
                  <div>
                    <p style={{ fontWeight: "bold" }}>Description:</p>
                    <p>{apiData?.description}</p>
                  </div>
                  <hr style={{ marginTop: 5, color: "gray" }} />
                  <div>
                    <p style={{ fontWeight: "bold" }}>Business Address:</p>
                    <p>{apiData?.business_address}</p>
                  </div>
                  <hr style={{ marginTop: 5, color: "gray" }} />
                  <div>
                    <p style={{ fontWeight: "bold" }}>
                      Business Registration Number:
                    </p>
                    <p>{apiData?.business_registration_number}</p>
                  </div>
                  <hr style={{ marginTop: 5, color: "gray" }} />
                  <div>
                    <p style={{ fontWeight: "bold" }}>Business Type:</p>
                    <p>{apiData?.business_type}</p>
                  </div>
                  <hr style={{ marginTop: 5, color: "gray" }} />
                  <div>
                    <p style={{ fontWeight: "bold" }}>Target Audience:</p>
                    <p>{apiData?.target_audience}</p>
                  </div>
                  <hr style={{ marginTop: 5, color: "gray" }} />
                  <div>
                    <p style={{ fontWeight: "bold" }}>Target Locations:</p>
                    <p>{apiData?.target_locations}</p>
                  </div>
                  <hr style={{ marginTop: 5, color: "gray" }} />
                  <div>
                    <p style={{ fontWeight: "bold" }}>PAN Number:</p>
                    <p>{apiData?.pan_number}</p>
                  </div>
                  <hr style={{ marginTop: 5, color: "gray" }} />
                  <div>
                    <p style={{ fontWeight: "bold" }}>GST Number:</p>
                    <p>{apiData?.gst_number}</p>
                  </div>
                </div>
              </>
            </div> */}

            {/* new design */}
            <div className="premium-container">
              <div className="premium-image-gallery">
                <div className="premium-main-image">
                  <img src={slides[1]} alt="Hotel pool view" />
                  <span className="premium-photo-count">
                    {slides?.length} PHOTOS
                  </span>
                </div>
                <div className="premium-thumbnails">
                  <img
                    src={slides[1]}
                    alt="alt"
                    className="premium-thumbnail-1"
                  />
                  <img
                    src={slides[1]}
                    alt="alt"
                    className="premium-thumbnail-2"
                  />
                  {/* <img
                    src={slides[1]}
                    alt="alt"
                    className="premium-thumbnail-3"
                  /> */}
                </div>
              </div>

              <nav className="premium-nav">
                <ul>
                  <li className="premium-active">Overview</li>
                  {/* <li>Rooms</li>
                  <li>Facilities</li>
                  <li>Reviews</li>
                  <li>Location</li>
                  <li>Policies</li> */}
                </ul>
                <a
                  href="https://play.google.com/store/apps/details?id=com.shadow.trendingsaround"
                  className="premium-view-deal"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ENQUIRE NOW
                </a>
              </nav>

              <div className="premium-hotel-info">
                <div className="premium-hotel-header">
                  <h1 style={{ fontSize: "30px" }}>{apiData?.business_name}</h1>
                  {/* <div className="premium-rating">
                    <Star />
                    <Star />
                    <Star />
                  </div> */}
                  <ExternalLink style={{ cursor: "pointer" }} onClick={shareButton} />
                </div>
                <Link
                  href={apiData?.google_map_link}
                  className="premium-location"
                >
                  <MapPin size={20} color="green" />
                  {apiData?.business_address}
                </Link>
                <p style={{ fontWeight: "bold", margin: "10px 0" }}>
                  Description:
                </p>
                <hr style={{ color: "#e0e0e0" }} />
                <p className="premium-description" style={{ margin: "10px 0" }}>
                  {apiData?.description}
                </p>
                <p style={{ fontWeight: "bold", margin: "10px 0" }}>
                  Brand Name:
                </p>
                <hr style={{ color: "#e0e0e0" }} />
                <p className="premium-description" style={{ margin: "10px 0" }}>
                  {apiData?.brand_name}
                </p>
                <p style={{ fontWeight: "bold", margin: "10px 0" }}>
                  Business Registration Number:
                </p>
                <hr style={{ color: "#e0e0e0" }} />
                <p className="premium-description" style={{ margin: "10px 0" }}>
                  {apiData?.business_registration_number}
                </p>
                <p style={{ fontWeight: "bold", margin: "10px 0" }}>
                  Business Type:
                </p>
                <hr style={{ color: "#e0e0e0" }} />
                <p className="premium-description" style={{ margin: "10px 0" }}>
                  {apiData?.business_type}
                </p>
                <p style={{ fontWeight: "bold", margin: "10px 0" }}>
                  Target Audience:
                </p>
                <hr style={{ color: "#e0e0e0" }} />
                <p className="premium-description" style={{ margin: "10px 0" }}>
                  {apiData?.target_audience}
                </p>
                <p style={{ fontWeight: "bold", margin: "10px 0" }}>
                  Business Registration Number:
                </p>
                <hr style={{ color: "#e0e0e0" }} />
                <p className="premium-description" style={{ margin: "10px 0" }}>
                  {apiData?.business_registration_number}
                </p>
              </div>

              <div className="premium-booking-details">
                <div className="premium-user-info">
                  <span style={{ fontWeight: "bold" }}>Date of upload: </span>
                  <span>Apr 4, 2023</span>
                </div>
                {/* <div className="premium-amenities">
                  <div>
                    <Wifi size={16} /> Free Wi-Fi
                  </div>
                  <div>
                    <Car size={16} /> Bike and Car
                  </div>
                  <div>
                    <Clock size={16} /> 12:00 AM
                  </div>
                </div> */}
                <button className="premium-get-owner">Get Owner Details</button>
              </div>

              <div className="premium-overall-rating">
                <span className="premium-rating-score">7.3</span>
                <div>
                  <h3>Very good</h3>
                  <p>508 reviews</p>
                </div>
              </div>
            </div>
          </div>
          {showShare && (
            <>
              <div className="shareBoxWrappeŗ-premium" onClick={shareButton}></div>
              <div className="shareBoxMainPage-premium">
                <p className="shareBoxText">Share</p>
                <div className="shareContainer">
                  <input
                    type="text"
                    className="shareSubBoxOne"
                    value={modCurrentUrl}
                    />
                  <div onClick={CopyUrl} className="shareBoxButton">
                    <ContentCopyIcon />
                  </div>
                </div>
                {showCopied && <p className="shareBoxCopied">Copied !</p>}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
